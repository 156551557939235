import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "../..";

import { useUserAction } from "../../../../hooks/useWorker";
import { RiskManagerModule } from "@/types/common/risk-inspector/common";

import { RequestDemoModal } from "./RequestDemo";
import { COLORS } from "@/const";

export default function FreeTrialButtonWithModal() {
  const { t } = useTranslation();
  const [, collect] = useUserAction();

  const [requestModalVisible, setRequestModalVisible] = useState(false);

  const onClick = useCallback(() => {
    collect(RiskManagerModule.RISK_INSPECTOR_SEARCH, "click request meeting button");
    setRequestModalVisible(true);
  }, [collect]);

  return (
    <>
      <Button
        v2
        type="primary"
        background={COLORS["CK2"]}
        label={"Start Your Free Trial"}
        onClick={onClick}
        style={{ border: "none" }}
      />
      <RequestDemoModal
        visible={requestModalVisible}
        onCloseModal={() => setRequestModalVisible(false)}
      />
    </>
  );
}
