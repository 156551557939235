import React, { useEffect, useMemo } from "react";
import { Layout } from "antd";
import styles from "./index.module.css";
import { useTheme } from "../../hooks/useTheme";
import AppHeader from "./AppHeader";
import { cls } from "../../utils/frontend/utils";

const { Header, Content, Footer } = Layout;
import Head from "next/head";
import { COLORS } from "../../const";
import { useRouter } from "next/router";
import { useStore, useVisibility } from "../../hooks";
import { PUBLIC_ROUTES, SHOW_UPGRADE_MODAL } from "../../const/appConfigs";
import { UpgradeModal } from "../SharedComponents/UpgradeModal";
import { useGlobalState } from "../../hooks/useLocalStorage";
import { previousRouteState } from "../../hooks/useAppContext";
import { useSetRecoilState, useRecoilValue } from "recoil";

export default function AppEntryLayout({
  children,
  title,
  hideHeader = false,
  footer: CustomizedFooter = null,
  ogImage,
  ogTitle,
  ogUrl,
  ogContent,
  style = {},
  contentStyle = {},
  headerProps,
  showAuthLinks = true,
  showOtherCertikAppLinks,
  feature,
  showFreeTrialLinks,
}) {
  const [theme] = useTheme();

  const [store] = useStore();
  const [isPageVisible, storeLoading] = useVisibility(feature);
  const router = useRouter();
  const globalStorageValue = useGlobalState(SHOW_UPGRADE_MODAL);

  const isShowUpgradeModal = useMemo(() => {
    // 计算逻辑基于 localStorageValue
    return globalStorageValue === "true" || globalStorageValue === true;
  }, [globalStorageValue]);
  const setPreviousRoute = useSetRecoilState(previousRouteState);
  const previousRoute = useRecoilValue(previousRouteState);

  useEffect(() => {
    const handleRouteChangeStart = (url) => {
      const prePath = previousRoute.current
        ? previousRoute.current.split("?")[0].split("#")[0]
        : "";
      if (router.pathname !== prePath) {
        setPreviousRoute({ prev: previousRoute.current, current: router.asPath });
      }
    };
    router.events.on("routeChangeStart", handleRouteChangeStart);

    return () => {
      router.events.off("routeChangeStart", handleRouteChangeStart);
    };
  }, [router.asPath]);

  useEffect(() => {
    if (
      // Note: must check false instead of null here
      storeLoading === false &&
      !isPageVisible &&
      !PUBLIC_ROUTES.includes(router.pathname)
    ) {
      router.push("/404");
    }
  }, [router, isPageVisible, storeLoading, store.userInfo?.userConfig?.enabledFeatures]);

  return (
    <Layout
      className={cls(
        styles,
        ["AppEntryLayout"],
        ["AppEntryLayout", theme === "dark" ? "dark" : "light"]
      )}
      style={style}
    >
      <Head>
        <title>{title}</title>
        <meta property="og:type" content="website" />
        <meta property="og:image" content={ogImage} />
        <meta property="og:title" content={ogTitle || "SkyHarbor"} />
        <meta property="og:description" content={ogContent} />
        <meta property="og:url" content={ogUrl} />
        <meta property="og:site_name" content="SkyHarbor" />
        <meta name="twitter:title" content={ogTitle || "SkyHarbor"} />
        <meta name="twitter:description" content={ogContent} />
        <meta name="twitter:image" content={ogImage} />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:url" content={ogUrl} />
      </Head>
      {!hideHeader && (
        <Header>
          <AppHeader
            logo={headerProps?.logo}
            logoHref={headerProps?.logoHref}
            showAuthLinks={showAuthLinks}
            showOtherCertikAppLinks={showOtherCertikAppLinks}
            showFreeTrialLinks={showFreeTrialLinks}
          />
        </Header>
      )}
      <Content style={contentStyle}>{children}</Content>
      {CustomizedFooter && (
        <Footer style={{ textAlign: "center", background: COLORS["gray-ext1"] }}>
          <CustomizedFooter />
        </Footer>
      )}
      {isShowUpgradeModal && <UpgradeModal visible={isShowUpgradeModal} />}
    </Layout>
  );
}
